<template>
  <TheHeader class="mb-4"></TheHeader>
  <div class="home">
    <section class="col-12 containerRelative">
      <div class="hero">
        <img
          src="../assets/background.png"
          alt="developer web png"
          class="image-background"
        />

        <div class="hero-text">
          <h1 class="pt-4 text-center" v-if="eng">
            <span class="typed-text">Benete Adrien</span><br />
            <span class="typed-text typed-text2">Web Developer</span><br />
            <span class="typed-text typed-text3">Back End</span><br />
            <span class="typed-text typed-text4">Front End</span>
          </h1>
          <h1 class="pt-4 text-center" v-else>
            <span class="typed-text">Benete Adrien</span><br />
            Webデベロッパー
            <br />
            <span class="typed-text typed-text3">Back End</span><br />
            <span class="typed-text typed-text4">Front End</span>
          </h1>
        </div>
      </div>
    </section>
    <section class="col-12" id="aboutMe">
      <div class="container">
        <h2 class="text-center" v-if="eng">About me ✍🏻</h2>
        <h2 class="text-center" v-else>私について ✍🏻</h2>
        <p class="aboutMePar" v-if="eng">
          <img
            src="../assets/profilePic.jpg"
            alt="id picture"
            class="w-25 h-25 mr-3 float-left rounded"
          />
          Over the past two years, I've embarked on an enriching journey in PHP
          development, starting from correcting errors on existing websites to
          spearheading the development of complex Laravel applications. After completing a
          six-month program, I dived headfirst into PHP, quickly gaining proficiency and
          taking on more responsibilities. As my expertise grew, I found myself connecting
          REST and GraphQL APIs, creating background jobs, and delving into the
          intricacies of Laravel. Beyond development, I've taken charge of deployment and
          maintenance tasks, ensuring the seamless operation of our applications.
          Investigating logs for bugs has become second nature, allowing me to maintain
          optimal performance and user experience. I'm proud to say that I've been making
          these contributions while living in Fukuoka Imajuku, Japan, and I'm delighted to
          continue building my career in this vibrant tech community. I used to live in
          France, China (3 years) and Australia (2 years), I am now based in Fukuoka Japan
          since 2019, although I recently passed the JLPT N2, I am still learning japanese
          every day. I can also speak english, french and chinese.
        </p>
        <p v-else>
          <img
            src="../assets/profilePic.jpg"
            alt="id picture"
            class="w-25 h-25 mr-3 float-left rounded"
          />
          この2年間、私はPHP開発の充実した旅に出た。 開発の充実した旅に出ました。
          複雑なLaravelアプリケーションの開発の陣頭指揮を執ってきました。半年間の
          ヶ月のプログラムを修了した後、PHPの世界に飛び込みました。
          より多くの責任を担うようになりました。専門知識が深まるにつれ RESTとGraphQL
          APIを接続し、バックグラウンドジョブを作成し、Laravelの複雑さを掘り下げていきました。
          Laravelの複雑さにも気づきました。開発だけでなく、デプロイやメンテナンスの仕事も担当するようになりました。
          保守作業を担当し、アプリケーションのシームレスな運用を保証しています。
          バグがないかログを調査することが自然にできるようになり、最適なパフォーマンスとユーザー体験を維持できるようになりました。
          最適なパフォーマンスとユーザーエクスペリエンスを維持できるようになりました。福岡に住みながら、このような貢献ができたことを誇りに思います。
          福岡今宿に住みながら、このような貢献をしてきたことを誇りに思います。
          この活気あるテック・コミュニティでキャリアを築き続けられることを嬉しく思っています。以前は
          以前はフランス、中国（3年）、オーストラリア（2年）に住んでいました。
          最近日本語能力試験N2に合格しましたが、まだ日本語を勉強中です。
          を毎日勉強しています。英語、フランス語、中国語も話せます。
        </p>
      </div>
      <div class="text-center mb-5 pb-3">
        <a
          :href="`${publicPath}Benete2024.pdf`"
          class="btn btn-secondary text-center"
          download="Benete2024.pdf"
          v-if="eng"
          ><i class="fas fa-download"></i> Download my resume in PDF</a
        >
        <a
          :href="`${publicPath}履歴書2024.pdf`"
          class="btn btn-secondary text-center"
          download="履歴書2024.pdf"
          v-else
          ><i class="fas fa-download"></i> PDF履歴書をダウンロード</a
        >
      </div>
    </section>
    <br />
    <h2 class="text-center pb-3" v-if="eng">My Skills 💪🏻</h2>
    <h2 class="text-center pb-3" v-else>スキル 💪🏻</h2>
    <div class="skills mt-3">
      <div class="card w-75 h-100 flex-row">
        <div class="logo-container">
          <img src="../assets/laravel.png" alt="logo laravel" class="rounded logo ml-4" />
        </div>

        <div class="card-body">
          <h4 class="card-title h5 h4-sm ml-3">Laravel</h4>
          <ul v-if="eng">
            <li>Integrated and managed two external APIs(REST and GraphQL).</li>
            <li>Implemented data transformations effectively.</li>
            <li>Utilized MVC architecture in project development.</li>
            <li>Managed queue jobs for background processing tasks.</li>
            <li>Scheduled automated tasks and processes.</li>
            <li>Worked with complex database systems.</li>
            <li>Optimized database performance and ensured data integrity.</li>
          </ul>
          <ul v-else>
            <li>外部API（RESTおよびGraphQL）の統合と管理を行いました。</li>
            <li>効果的なデータ変換を実装しました。</li>
            <li>プロジェクト開発においてMVCアーキテクチャを利用しました。</li>
            <li>バックグラウンド処理タスクのためのキュージョブを管理しました。</li>
            <li>自動化されたタスクおよびプロセスをスケジューリングしました。</li>
            <li>複雑なデータベースシステムを取り扱いました。</li>
            <li>
              データベースのパフォーマンスを最適化し、データの整合性を確保しました。
            </li>
          </ul>
        </div>
      </div>
      <div class="card w-75 h-100 mt-4 flex-row">
        <div class="logo-container">
          <img src="../assets/php.png" alt="logo laravel" class="rounded logo ml-4" />
        </div>

        <div class="card-body">
          <h4 class="card-title h5 h4-sm ml-3">PHP</h4>
          <ul v-if="eng">
            <li>
              Proficient in PHP development, with experience in building web applications.
            </li>
            <li>
              Familiar with the CurryPHP framework and its usage in project development.
            </li>
            <li>Integrated and managed external APIs within PHP applications.</li>
            <li>
              Implemented data transformations and processing efficiently using PHP.
            </li>
            <li>
              Utilized MVC architecture in PHP projects for code organization and
              maintainability.
            </li>
            <li>
              Worked with queue systems for background job processing in PHP applications.
            </li>
            <li>
              Automated tasks and scheduled processes using PHP's scheduling features.
            </li>
            <li>
              Managed complex database systems and optimized database performance in PHP
              applications.
            </li>
          </ul>
          <ul v-else>
            <li>PHP開発に熟練しており、Webアプリケーションの構築経験があります。</li>
            <li>
              プロジェクト開発におけるCurryPHPフレームワークの使用に精通しています。
            </li>
            <li>PHPアプリケーション内で外部APIの統合と管理を行いました。</li>
            <li>PHPを使用してデータ変換と処理を効率的に実装しました。</li>
            <li>
              コードの組織化と保守性のために、PHPプロジェクトでMVCアーキテクチャを利用しました。
            </li>
            <li>
              PHPアプリケーションでのバックグラウンドジョブ処理のためにキューシステムを使用しました。
            </li>
            <li>
              PHPのスケジューリング機能を使用してタスクを自動化し、プロセスをスケジューリングしました。
            </li>
            <li>
              複雑なデータベースシステムを管理し、PHPアプリケーションでデータベースのパフォーマンスを最適化しました。
            </li>
          </ul>
        </div>
      </div>
      <div class="card w-75 h-100 flex-row mt-4">
        <div class="logo-container">
          <img src="../assets/ubuntu.png" alt="logo mysql" class="rounded logo ml-4" />
        </div>

        <div class="card-body">
          <h4 class="card-title h5 h4-sm ml-3">Ubuntu</h4>
          <ul v-if="eng">
            <li>
              Proficient in Ubuntu server administration, including tasks such as
              restarting Apache 2 and investigating server logs.
            </li>
            <li>
              Experienced in launching and deploying applications on Ubuntu servers,
              utilizing deployment tools like Deployer for streamlined deployment
              processes.
            </li>
            <li>
              Skilled in understanding the directory structure of Ubuntu servers,
              including navigating file systems, managing permissions, and configuring
              server settings.
            </li>
            <li>
              Familiar with managing SSL certificates on Ubuntu servers, including the
              process of renewing SSL certificates for secure communication.
            </li>
            <li>
              Capable of using WinSCP for secure file transfer and remote file management
              on Ubuntu servers, enhancing efficiency in server administration tasks.
            </li>
          </ul>
          <ul v-else>
            <li>
              Ubuntuサーバーの管理に熟練しており、Apache
              2の再起動やサーバーログの調査などのタスクを含みます。
            </li>
            <li>
              デプロイメントツール（Deployerなど）を活用した、Ubuntuサーバーへのアプリケーションの起動およびデプロイに経験があります。
            </li>
            <li>
              Ubuntuサーバーのディレクトリ構造を理解しており、ファイルシステムのナビゲーション、権限管理、サーバー設定の構成に熟練しています。
            </li>
            <li>
              Ubuntuサーバー上でのSSL証明書の管理に精通しており、SSL証明書の更新プロセスも含め、セキュアな通信を確保できます。
            </li>
            <li>
              WinSCPを使用して、Ubuntuサーバー上での安全なファイル転送およびリモートファイル管理が可能であり、サーバー管理タスクの効率を向上させます。
            </li>
          </ul>
        </div>
      </div>
      <div class="card w-75 h-100 flex-row mt-4">
        <div class="logo-container">
          <img src="../assets/mysql.png" alt="logo mysql" class="rounded logo ml-4" />
        </div>

        <div class="card-body">
          <h4 class="card-title h5 h4-sm ml-3">MYSQL</h4>
          <ul v-if="eng">
            <li>
              Proficient in MySQL database management, including creating and maintaining
              database structures.
            </li>
            <li>
              Experienced in creating SQL dumps for database backup and restoration.
            </li>
            <li>
              Skilled in executing native MySQL commands for data manipulation and query
              optimization.
            </li>
            <li>
              Capable of efficiently adding files and binary data into MySQL databases
              using appropriate storage techniques.
            </li>
            <li>
              Familiar with advanced MySQL features such as stored procedures, triggers,
              and transactions.
            </li>
            <li>
              Knowledgeable in MySQL security practices, including user management and
              access control.
            </li>
          </ul>
          <ul v-else>
            <li>
              MySQLデータベース管理に熟練しており、データベース構造の作成および維持ができます。
            </li>
            <li>
              データベースのバックアップおよび復元のためのSQLダンプ作成に経験があります。
            </li>
            <li>
              データ操作およびクエリ最適化のためのネイティブMySQLコマンドの実行に熟練しています。
            </li>
            <li>
              適切なストレージ技術を使用して、ファイルおよびバイナリデータを効率的にMySQLデータベースに追加することができます。
            </li>
            <li>
              ストアドプロシージャ、トリガー、およびトランザクションなどの高度なMySQL機能に精通しています。
            </li>
            <li>
              ユーザー管理およびアクセス制御を含むMySQLのセキュリティ実践に関する知識があります。
            </li>
          </ul>
        </div>
      </div>
      <div class="card w-75 h-100 flex-row mt-4">
        <div class="logo-container">
          <img
            src="../assets/Nextengine.png"
            alt="logo mysql"
            class="rounded logo ml-4"
          />
        </div>

        <div class="card-body">
          <h4 class="card-title h5 h4-sm ml-3">NextEngine System</h4>
          <ul v-if="eng">
            <li>Proficient in managing orders and products using the Nextengine API.</li>
            <li>
              Experienced in connecting to the Nextengine API securely using
              authentication tokens.
            </li>
            <li>
              Skilled in integrating Nextengine API functionality into Laravel
              applications.
            </li>
            <li>
              Capable of utilizing packages like ShibuyaKosuke to streamline API
              integration processes in Laravel projects.
            </li>
            <li>
              Familiar with API endpoints, request methods, and data formats supported by
              the Nextengine API.
            </li>
            <li>
              Knowledgeable in error handling, rate limiting, and other considerations
              when interacting with the Nextengine API.
            </li>
          </ul>
          <ul v-else>
            <li>Nextengine APIを使用して注文や商品の管理に熟練しています。</li>
            <li>認証トークンを使用してNextengine APIに安全に接続する経験があります。</li>
            <li>
              Nextengine APIの機能をLaravelアプリケーションに統合するスキルがあります。
            </li>
            <li>
              ShibuyaKosukeなどのパッケージを利用して、LaravelプロジェクトでのAPI統合プロセスを効率化することができます。
            </li>
            <li>
              Nextengine
              APIがサポートするAPIエンドポイント、リクエストメソッド、およびデータフォーマットに精通しています。
            </li>
            <li>
              Nextengine
              APIとのやり取りにおけるエラーハンドリング、レート制限、その他の考慮事項に関する知識があります。
            </li>
          </ul>
        </div>
      </div>
      <div class="card w-75 h-100 flex-row mt-4">
        <div class="logo-container">
          <img src="../assets/docker.png" alt="logo mysql" class="rounded logo ml-4" />
        </div>

        <div class="card-body">
          <h4 class="card-title h5 h4-sm ml-3">Docker</h4>
          <ul v-if="eng">
            <li>
              Proficient in Docker for creating development environments, allowing for
              consistent and reproducible setups across different machines.
            </li>
            <li>
              Experienced in using Laradock, a Docker-based development environment for
              PHP projects, including Laravel applications.
            </li>
            <li>
              Skilled in integrating Docker with Laravel and Sails.js, enabling efficient
              development workflows and easy deployment.
            </li>
            <li>
              Familiar with Docker Compose for defining and running multi-container Docker
              applications, simplifying the management of complex development
              environments.
            </li>
            <li>
              Capable of leveraging Docker for isolating dependencies and ensuring
              compatibility between different components of web applications.
            </li>
          </ul>
          <ul v-else>
            <li>
              Dockerを使用して開発環境を作成し、異なるマシン間で一貫性のある再現可能なセットアップを実現することに熟練しています。
            </li>
            <li>
              Laradockを使用したPHPプロジェクト（Laravelアプリケーションを含む）のDockerベースの開発環境に経験があります。
            </li>
            <li>
              DockerをLaravelおよびSails.jsと統合し、効率的な開発ワークフローと簡単なデプロイを実現するスキルがあります。
            </li>
            <li>
              複雑な開発環境の管理を簡素化するために、Docker
              Composeを使用してマルチコンテナDockerアプリケーションを定義および実行することに精通しています。
            </li>
            <li>
              Dockerを活用して依存関係を分離し、Webアプリケーションの異なるコンポーネント間の互換性を確保することができます。
            </li>
          </ul>
        </div>
      </div>
      <div class="card w-75 h-100 flex-row mt-4">
        <div class="logo-container">
          <img src="../assets/js.png" alt="logo mysql" class="rounded logo ml-4" />
        </div>
        <div class="card-body">
          <h4 class="card-title h5 h4-sm ml-3">JavaScript</h4>
          <ul v-if="eng">
            <li>
              Proficient in JavaScript development, with the ability to create dynamic and
              interactive user interfaces, including animations.
            </li>
            <li>
              Experienced in integrating JavaScript with Laravel applications using
              Livewire, facilitating real-time updates and interactions without writing
              JavaScript code.
            </li>
            <li>
              Familiar with jQuery for simplifying DOM manipulation, event handling, and
              AJAX requests in JavaScript-based applications.
            </li>
            <li>
              Skilled in utilizing localStorage for client-side data storage in web
              applications, enabling persistent data storage across browser sessions.
            </li>
            <li>
              Capable of implementing animations and transitions in JavaScript to enhance
              user experience and visual appeal of web applications.
            </li>
          </ul>
          <ul v-else>
            <li>
              JavaScript開発に熟練しており、アニメーションを含む動的でインタラクティブなユーザーインターフェースを作成する能力があります。
            </li>
            <li>
              LaravelアプリケーションにJavaScriptをLivewireで統合し、JavaScriptコードを書かずにリアルタイムの更新とインタラクションを実現する経験があります。
            </li>
            <li>
              JavaScriptベースのアプリケーションにおけるDOM操作、イベント処理、AJAXリクエストを簡素化するためのjQueryに精通しています。
            </li>
            <li>
              ウェブアプリケーションでのクライアントサイドデータストレージのためにlocalStorageを利用し、ブラウザセッション間でデータを永続的に保存するスキルがあります。
            </li>
            <li>
              ユーザー体験と視覚的な魅力を向上させるために、JavaScriptでアニメーションとトランジションを実装することができます。
            </li>
          </ul>
        </div>
      </div>

      <div class="card w-75 h-100 flex-row mt-4">
        <div class="logo-container">
          <img src="../assets/logo.png" alt="logo mysql" class="rounded logo ml-4" />
        </div>

        <div class="card-body">
          <h4 class="card-title h5 h4-sm ml-3">Vue.Js</h4>
          <ul v-if="eng">
            <li>
              Proficient in integrating Vue.js with Laravel applications to create dynamic
              user interfaces.
            </li>
            <li>
              Skilled in utilizing Vue components within Laravel blade templates for
              enhanced frontend interactivity.
            </li>
            <li>
              Familiar with Laravel Mix for seamless asset compilation and versioning with
              Vue.js.
            </li>
            <li>
              Capable of integrating Vue.js with Laravel's backend APIs for efficient data
              exchange.
            </li>
          </ul>
          <ul v-else>
            <li>
              LaravelアプリケーションにVue.jsを統合し、動的なユーザーインターフェースを作成するのに熟練しています。
            </li>
            <li>
              Laravelのブレードテンプレート内でVueコンポーネントを利用し、フロントエンドのインタラクティブ性を向上させるスキルがあります。
            </li>
            <li>
              Vue.jsとシームレスなアセットコンパイルおよびバージョン管理を行うためにLaravel
              Mixを利用することに精通しています。
            </li>
            <li>
              効率的なデータ交換のために、Vue.jsをLaravelのバックエンドAPIと統合することができます。
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Tools class="mb-4"></Tools>
    <h2 class="text-center pt-4 mb-5 mt-3" id="myWorks" v-if="eng">
      Personal project <span>🚀</span>
    </h2>
    <h2 class="text-center pt-4 mb-5 mt-3" id="myWorks" v-else>
      プロジェクト <span>🚀</span>
    </h2>
    <br />
    <div class="works">
      <div class="card mb-5">
        <img class="card-img-top" src="..\assets\groupomania.jpg" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title" v-if="eng">
            Built a Full-Stack Corporate Social Network
          </h5>
          <h5 class="card-title" v-else>
            フルスタックのソーシャルネットワークを構築する
          </h5>
          <p class="card-text" v-if="eng">
            Analyze a client’s needs to define the scope and features of a new
            application. With the help of a front-end framework (Vue.js), built a
            full-stack solution, including a SQL database. The user is able to share a
            post, like and comment.
          </p>
          <p class="card-text" v-else>
            クライアントのニーズを分析して、新しいアプリケーションの範囲と機能を定義します。
            フロントエンドフレームワーク（Vue.js）を使用して、SQLデータベースを含むフルスタックソリューションを構築します。
            ユーザーは、投稿、いいね、コメントを共有できます。
          </p>
          <div class="pb-3">
            <img
              src="../assets/logo.png"
              alt="logo vue"
              class="logo"
              data-aos="fade-right"
            />
            <img
              src="../assets/vuex.png"
              alt="logo vue"
              class="logo"
              data-aos="fade-right"
            />
            <img
              src="../assets/mysql.png"
              alt="logo mysql"
              class="logo"
              data-aos="fade-right"
            />
            <img
              src="../assets/node.png"
              alt="logo node"
              class="logo node"
              data-aos="fade-right"
            />
            <img
              src="../assets/ux.png"
              alt="logo design designGroupo"
              class="logo ux"
              data-aos="fade-right"
            />
          </div>
          <div v-if="eng">
            <a href="https://youtu.be/2UmIfHTFXOA" class="btn btn-primary"
              >Check the video Demo</a
            >
            <a
              href="https://github.com/adriben/AdrienBenete_7_19112021-"
              class="btn btn-secondary ml-3"
              >Check the code</a
            >
          </div>
          <div v-else>
            <a href="https://youtu.be/2UmIfHTFXOA" class="btn btn-primary"
              >Youtubeビデオに移動する</a
            >
            <a
              href="https://github.com/adriben/AdrienBenete_7_19112021-"
              class="btn btn-secondary ml-3"
              >コードに移動する</a
            >
          </div>
        </div>
      </div>
      <!-- <div class="card mb-5">
        <img class="card-img-top" src="..\assets\fukubook.jpg" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title" v-if="eng">Built a full-stack EC website</h5>
          <h5 class="card-title" v-else>フルスタックのeコマースWebサイトを構築する</h5>
          <p class="card-text" v-if="eng">
            Creation of a website for people who are looking to exchange give or sell
            books in Fukuoka. With the help of a front-end framework (Vue.js), built a
            full-stack solution, including a NoSQL database. (In developement)
          </p>
          <p class="card-text" v-else>
            福岡で外国の本を交換したり、交換したりする人のためのウェブサイトを作成。フロントエンドフレームワーク（Vue.js）を使用して、NoSQLデータベースを含むフルスタックソリューションを構築します。（開発中）
          </p>
          <div class="pb-3">
            <img
              src="../assets/logo.png"
              alt="logo vue"
              class="logo"
              data-aos="fade-right"
            />
            <img
              src="../assets/vuex.png"
              alt="logo vue"
              class="logo"
              data-aos="fade-right"
            />
            <img
              src="../assets/node.png"
              alt="logo node"
              class="logo node"
              data-aos="fade-right"
            />
            <img
              src="../assets/ux.png"
              alt="logo design designGroupo"
              class="logo ux"
              data-aos="fade-right"
            />
          </div>
          <div v-if="eng">
            <a href="https://github.com/adriben/fukubook" class="btn btn-secondary ml-3"
              >Check the code</a
            >
          </div>
          <div v-else>
            <a href="https://github.com/adriben/fukubook" class="btn btn-secondary ml-3"
              >コードに移動する</a
            >
          </div>
        </div>
      </div> -->
    </div>

    <div class="black pt-4 bg-dark">
      <div class="text-center" id="contact">
        <h2 v-if="eng">Contact ☎️</h2>
        <h2 v-else>連絡先 ☎️</h2>
        <Contact></Contact>
      </div>
      <div class="text-center mt-4">
        <h3 v-if="eng">Hobbies 🏖</h3>
        <h3 v-else>趣味 🏖</h3>
        <Hobbies class="container pb-5"></Hobbies>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TheHeader from "@/components/TheHeader.vue";
import Hobbies from "@/components/Hobbies.vue";
import Contact from "@/components/Contact.vue";
import Tools from "@/components/Tools.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "Home",
  components: {
    TheHeader: TheHeader,
    Hobbies: Hobbies,
    Contact: Contact,
    Tools: Tools,
  },
  data: () => {
    return {
      typeValue: "",
      typeStatus: false,
      typeArray: ["Front end", "Back end", "Full stack"],
      typingSpeed: 200,
      erasingSpeed: 100,
      newTextDelay: 1500,
      typeArrayIndex: 0,
      charIndex: 0,
      publicPath: process.env.BASE_URL,
      eng: true,
    };
  },
  methods: {
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
        this.charIndex += 1;

        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;

        this.typeValue = this.typeArray[this.typeArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length) this.typeArrayIndex = 0;

        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
    changeLang() {
      this.eng = !this.eng;
    },
    replaceText() {},
  },

  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
    AOS.init();
  },
};
</script>
<style lang="scss">
.loader {
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 12, 12, 0.541);
  position: fixed;
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
}
.home {
  background-color: rgba(255, 255, 255, 0.685);
}

img {
  width: 100%;
  margin: auto;
  object-fit: cover;
}

.hero {
  position: relative;
  padding-top: 40px;
  @media screen and (max-width: 400px) {
    padding-top: 60px;
  }

  .hero-text {
    position: absolute;
    align-items: center;
    top: 20%;
    left: 5%;
    @media screen and (max-width: 800px) {
      top: 80%;
      left: 25%;
    }
    @media screen and (max-width: 400px) {
      top: 80%;
      left: 7%;
    }
    h1 {
      animation: slideDisplay 500ms;
      span.typed-text {
        color: black;
      }
      span.typed-text {
        display: inline-block;
        opacity: 0; /* Initially hide all text */
        animation-fill-mode: forwards; /* Retain styles after animation */
      }

      /* Apply animations to each text element */
      span.typed-text:nth-child(1) {
        animation: slideDisplay 500ms forwards;
        animation-delay: 0ms; /* Delay first text */
      }

      span.typed-text2 {
        animation: slideDisplay 500ms forwards;
        animation-delay: 500ms; /* Delay second text */
      }

      span.typed-text3 {
        animation: slideDisplay 500ms forwards;
        animation-delay: 700ms; /* Delay third text */
      }

      span.typed-text4 {
        animation: slideDisplay 500ms forwards;
        animation-delay: 800ms; /* Delay fourth text */
      }
    }
  }
}
.devWebP {
  width: 27rem;
  position: absolute;
  top: 40%;
  left: 10%;
  z-index: 10;
  @media screen and (max-width: 900px) {
    width: 20rem;
    top: 50%;
    left: 20%;
  }

  @media screen and (max-width: 580px) {
    top: 50%;
    left: 20%;
  }
  @media screen and (max-width: 400px) {
    top: 60%;
    left: 10%;
    width: 15rem;
  }
  @media screen and (max-width: 300px) {
    top: 60%;
    left: 0%;
    width: 15rem;
  }
}
.svg {
  position: absolute;
  top: 40%;
  left: 0%;
  z-index: 6;
  @media screen and (max-width: 1640px) {
    top: 50%;
    left: 0%;
  }
  @media screen and (max-width: 1440px) {
    top: 60%;
    left: 0%;
  }
  @media screen and (max-width: 1100px) {
    top: 70%;
    left: 0%;
  }
  @media screen and (max-width: 900px) {
    top: 80%;
    left: 0%;
  }
  @media screen and (max-width: 650px) {
    top: 85%;
    left: 0%;
  }
  @media screen and (max-width: 470px) {
    top: 87%;
    left: 0%;
  }
  @media screen and (max-width: 400px) {
    top: 89%;
    left: 0%;
  }
  @media screen and (max-width: 365px) {
    top: 92%;
    left: 0%;
  }
}

#aboutMe {
  margin-top: -100px;
  @media screen and (max-width: 1000px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 200px;
  }
  @media screen and (max-width: 400px) {
    margin-top: 250px;
  }
}

.id-pic {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 150px;
  position: absolute;
  left: -120px;
  top: 50px;
  z-index: 11;
  @media screen and (max-width: 1400px) {
    left: 40%;
    margin: 0 auto;
    top: 0px;
  }
}
.aboutMePar {
  font-size: 120%;
  font-family: "Fuzzy Bubbles", cursive;
}
.logo {
  width: 80px;
  padding-right: 1rem;
}
.css {
  width: 60px;
}

.btn-secondary {
  background-color: #d85c3a;
}

.works {
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (max-width: 1900px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
}

.card {
  width: 35rem;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  box-shadow: 3px 3px 7px rgba(63, 62, 62, 0.596);
  @media screen and (max-width: 600px) {
    width: 100%;
    a.btn-secondary {
      margin-top: 0.8rem;
    }
    @media screen and (max-width: 400px) {
      width: 100%;
      border-radius: 0px;
      box-shadow: none;
      a.btn-secondary {
        margin-top: 0.8rem;
      }
    }
  }

  .card-img-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 29rem;
    object-fit: cover;
    border-bottom: 1px solid rgba(128, 128, 128, 0.308);
    @media screen and (max-width: 400px) {
      border-radius: 0px;
    }
  }
  .logo {
    width: 150px;
    padding-right: 1rem;
    transition: 100ms;
  }
  .css {
    width: 50px;
  }
  .ux {
    width: 80px;
  }
  .analytics {
    width: 7rem;
  }
  .node {
    width: 6rem;
  }
  .nuxt {
    width: 9rem;
  }
  .heroku {
    width: 3.5rem;
  }
  .reservia {
    object-fit: cover;
    object-position: left top;
  }
  .ohmyfood {
    object-fit: cover;
    object-position: left top;
  }
  .iphone {
    height: 16rem;
    width: 7.6rem;
    position: absolute;
    top: 235px;
    right: 20px;
    background-size: 8rem;
    border-radius: 15px;
    box-shadow: 5px 3px 5px rgba(63, 62, 62, 0.596);
    z-index: 3;
  }
  .performance {
    height: 16rem;
    width: 25rem;
    position: absolute;
    top: 220px;
    right: -20px;
    background-size: 8rem;
    border: grey 1px solid;
    border-radius: 15px;
    box-shadow: 5px 3px 5px rgba(63, 62, 62, 0.596);
    z-index: 3;
    @media screen and (max-width: 630px) {
      height: 10rem;
      width: 20rem;
      right: 20px;
      top: 320px;
    }
  }
}

.phone {
  animation: shake 3s;
  color: red;

  animation-iteration-count: infinite;
}
.black {
  color: rgb(255, 255, 255);

  @media screen and (min-width: 1100px) {
    border-radius: 40% 40% 0 0;
  }
}
@keyframes cursorBlink {
  49% {
    background-color: #fff;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}
@keyframes slideDisplay {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  50% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@media screen and (max-width: 630px) {
  .card {
    display: flex;
    flex-direction: column !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    img {
      display: inline-block !important; /* or inline */
    }
  }
}
.image-background {
  max-width: 1900px;
}
</style>
