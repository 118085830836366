<template>
<h2 class="text-center pb-3">My Tools🧰</h2>
  <div class="tools text-center">
    <div class="tools-el">
      <img src="../assets/windows.png" alt="logo windows" class="rounded logo ml-4" />
      <p>Windows</p>
    </div>
    <div class="tools-el">
      <img src="../assets/linux.png" alt="logo linux" class="rounded logo ml-4" />
      <p>WSL</p>
    </div>

    <div class="tools-el">
     <img src="../assets/vscode.png" alt="logo vscode" class="rounded logo ml-4" />
      <p>Vs Code</p>
    </div>
    <div class="tools-el">
     <img src="../assets/git.png" alt="logo git" class="rounded logo ml-4" />
      <p>Git</p>
    </div>

    <div class="tools-el">
      <img src="../assets/workbench.png" alt="logo workbench" class="rounded logo ml-4" />
      <p>WorkBench</p>
    </div>
    <div class="tools-el">
      <img src="../assets/winscp.jpg" alt="logo winscp" class="rounded logo ml-4" />
      <p>WinSCP</p>
    </div>
    <div class="tools-el">
      <img src="../assets/Jira.jpg" alt="logo jira" class="rounded logo ml-4" />
      <p>Jira</p>
    </div>
    <div class="tools-el">
      <img src="../assets/slack.png" alt="logo slack" class="rounded logo ml-4" />
      <p>Slack</p>
    </div>
     <div class="tools-el">
      <img src="../assets/excel.png" alt="logo windows" class="rounded logo ml-4" />
      <p>Excel</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tools",
};
</script>

<style lang="scss">
.tools {
  margin: 1rem 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
  align-content: center;
  justify-items: center;
  @media screen and (max-width: 970px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
  .tools-el {
    font-size: 130%;
  }
}
</style>
