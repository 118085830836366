<template>
  <div v-if="isLoading" class="spinner-overlay">
    <div class="spinner"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: true // Initially set to true
    };
  },
  mounted() {
    window.onload = () => {
      // Page is fully loaded, hide the spinner
      this.isLoading = false;
    };
  },
  beforeUnmount() {
    // Remove the onload event listener when the component is destroyed
    window.onload = null;
  },
};
</script>

<style>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1); /* Semi-transparent white background */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>